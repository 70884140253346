<template>
  <v-card>
    <v-card-text>
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Arızalar"
            icon="mdi-hammer-wrench"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :hide-edit="!can('edit-breakdown')"
            :edit-enabled="selectedItems.length === 1"
            @click:edit="handleEditClick"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
            :add-route="
              can('edit-breakdown')
                ? { name: 'communication.breakdowns.create' }
                : null
            "
            :add-enabled="clusterId"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'communication.breakdowns.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.provider="{ item }">
          <router-link
            v-if="item.provider_id"
            :to="{
              name: 'definitions.cluster-providers.show',
              params: { id: item.provider_id },
            }"
          >
            {{ item.provider }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.resident="{ item }">
          <router-link
            v-if="item.resident_id"
            :to="{
              name: 'definitions.residents.show',
              params: { id: item.resident_id },
            }"
          >
            {{ item.resident }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.breakdown_type="{ item }">
          <router-link
            v-if="item.breakdown_type_id && can('see-control-type')"
            :to="{
              name: 'other-definitions.types.breakdown-types.show',
              params: { id: item.breakdown_type_id },
            }"
          >
            {{ item.breakdown_type }}
          </router-link>
          <template v-else>
            {{ item.breakdown_type }}
          </template>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.subject="{ value }">
          <rs-table-cell-string :value="value" :limit="20" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.date="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.description="{ value }">
          <rs-table-cell-string :limit="20" :value="value" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";

export default {
  name: "BreakdownList",
  mixins: [filtersToURL, hasExport, hasPermissions],
  computed: {
    ...mapGetters([
      "companyId",
      "clusterId",
      "careStatusList",
      "careTargetList",
    ]),
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
    companyId() {
      this.loadList();
    },
  },
  data() {
    return {
      titleBarAttrs: {
        exportUrl: "breakdowns",
        exportParams: this.getParams,
      },
      search: {
        status_ids: [10, 1, 5],
      },
      options: {
        sortBy: ["date"],
        sortDesc: [false],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-breakdown"),
        },
        {
          text: "TYA",
          value: "cluster",
          searchable: "cluster",
          multiple: true,
          hide: () => this.clusterId,
        },
        {
          text: "Tarih",
          value: "date",
          searchable: "date",
        },
        {
          text: "Arıza Tipi",
          value: "breakdown_type",
          searchable: "text",
          width: "100px",
        },
        {
          text: "Arıza Şekli",
          value: "target",
          searchable: "multiselect",
          options: () => this.careTargetList,
          width: "100px",
        },
        {
          text: "Konu",
          value: "subject",
          searchable: "text",
        },
        {
          text: "Arıza Giderecek",
          value: "provider",
          searchable: "text",
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: "text",
        },
        {
          text: "Arıza Bildiren",
          value: "resident",
          searchable: "text",
        },
        {
          text: "Arıza Durumu",
          value: "status",
          searchValue: "status_ids",
          searchable: "multiselect",
          options: () => this.careStatusList,
          sortable: false,
          width: "280px",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["updateBreakdownCount", "updateBreakdownRecentList"]),
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (this.clusterId) {
        params.cluster_id = this.clusterId;
      }

      if (this.companyId) {
        params.company_id = this.companyId;
      }

      return params;
    },
    loadList() {
      if (this.isLoading) {
        this.resetTable();
        return false;
      }

      this.resetTable();
      this.isLoading = true;

      const params = this.getParams();

      this.$api
        .query("breakdowns", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
            this.updateBreakdownRecentList(response.data.data);
            this.updateBreakdownCount(response.data.meta.total);
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.isLoading || this.selectedItems.length !== 1) {
        return false;
      }

      this.$router.push({
        name: "communication.breakdowns.edit",
        params: {
          id: this.selectedItems[0].id,
        },
      });
    },
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
};
</script>
